/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
// ./gatsby-browser.js
import "./src/styles/grid.scss";
import "./src/styles/style.scss";
import "./src/styles/nav.scss";
export const onServiceWorkerUpdateReady = () => {
  console.log('SW UpdateReady')
  const answer = window.confirm(
    `There has been an update to SaltSmart. ` +
      `Update to latest version?`
  )
  if (answer === true) {
    window.location.reload()
  }
}

export const onServiceWorkerUpdateFound = () => {
  console.log('New SW Found')
}
export const onServiceWorkerInstalled = () => {
  console.log('SW Istalled')
}
export const onServiceWorkerRedundant  = () => {
  console.log('SW Redundant')
}
export const onServiceWorkerActive = () => {
  console.log('SW Active')
}

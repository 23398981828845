module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SaltSmart","short_name":"SaltSmart","start_url":"/?source=pwa","scope":"/","background_color":"#fff","theme_color":"#00ACED","display":"standalone","icon":"src/images/Compass_Salt_appIcon.png","cache_busting_mode":"none","icons":[{"src":"/apple-touch-icon.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"crossOrigin":"anonymous","include_favicon":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-modal-routing/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["GTM-5XX77PD"],"pluginConfig":{"head":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"workboxConfig":{"cacheId":"cmpV4.06.23.10.a","globPatterns":["**/*.{js,json,html,woff2,ttf,svg,png,jpg,pdf}"],"maximumFileSizeToCacheInBytes":50000000,"cleanupOutdatedCaches":true,"runtimeCaching":[{"urlPattern":{},"handler":"CacheFirst"}]}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
